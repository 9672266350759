import { useSelector } from "react-redux";
import axiosInstance from "../services/axiosIntercept";
import { AxiosResponse } from "axios";
import * as selectors from "../selectors/selectors";

const useCheckVoucher = () => {
  const accountUuid = useSelector(selectors.getAccountUuid);

  const checkVoucherLimit = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/merchantSetup/active/campaign/check`,
        {
          accountUuid: accountUuid,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return checkVoucherLimit;
};

export default useCheckVoucher;
