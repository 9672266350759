import { useNavigate } from "react-router-dom";
import logoutService from "../services/authService"; // Import the logout service
import { persist } from "../store";
import { useDispatch } from "react-redux";
import { logout } from "../actions/actions";

const useGetLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLogout = async () => {
    try {
      const result = await logoutService();
      if (result && result.success) {
        dispatch(logout());

        // Wait for the 'persist/REHYDRATE' action to be processed
        await persist.dispatch({ type: "persist/REHYDRATE" } as any);

        // Purge the persisted state
        await persist.purge();

        // Remove the 'persist:root' key
        //  localStorage.removeItem("persist:root");

        // Clear localStorage and Redux state
        // Only clear localStorage items that need to be cleared.
        ["Industries", "NewUser", "editId", "source"].forEach((key) => {
          localStorage.removeItem(key);
        });

        // Navigate to login
        navigate("/");
      }
    } catch (error: any) {
      console.error("Logout failed:", error.message);
    }
  };

  return getLogout;
};

export default useGetLogout;
