import { AxiosResponse, isAxiosError } from "axios";
import axiosInstance from "./axiosIntercept";
import { Voucher } from "../types";

type MilestoneCampaignThresholdReward = {
  bankVoucherCampaignId: number;
  id: number;
  milestoneStampcardVoucherCampaignId: number;
  stampingDigitalVoucherIssueThreshold: number;
};
export const getMilestoneCampaignThresholdRewards = async (
  milestoneCampaignId: number | string,
): Promise<MilestoneCampaignThresholdReward[]> => {
  try {
    const response = await axiosInstance.post<
      any,
      AxiosResponse<MilestoneCampaignThresholdReward[]>,
      { milestoneVoucherId: string | number }
    >(
      "v1/voucher/milestone/threshold/rewards",
      {
        milestoneVoucherId: milestoneCampaignId,
      },
      {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};

export const getMilestoneCampaign = async (
  programUuid: number | string,
): Promise<Voucher | undefined> => {
  try {
    const { data } = await axiosInstance.get<Voucher[] | string>(
      "/v1/voucher/milestone",
      {
        params: {
          programUuid,
        },
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );

    if (typeof data === "string") {
      return undefined;
    }

    if (!Array.isArray(data)) {
      return undefined;
    }

    // First try to find a valid milestone voucher
    const validMilestoneVoucher = data.find(
      (voucher) => voucher.isValid && voucher.isMilestoneStampcard,
    );

    if (validMilestoneVoucher) {
      // console.log("Found valid milestone voucher:", validMilestoneVoucher);
      return validMilestoneVoucher;
    }

    // If no valid voucher found, get the latest invalid milestone voucher
    const latestInvalidVoucher = data
      .filter((voucher) => !voucher.isValid && voucher.isMilestoneStampcard)
      .sort(
        (a, b) =>
          new Date(b.lastUpdatedTime).getTime() -
          new Date(a.lastUpdatedTime).getTime(),
      )[0];

    // console.log(
    //   "Found latest invalid milestone voucher:",
    //   latestInvalidVoucher,
    // );
    return latestInvalidVoucher;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};
