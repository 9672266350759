import { AxiosResponse, isAxiosError } from "axios";
import axiosInstance from "../services/axiosIntercept";
import { ProgramLocation } from "../types";

type NewProgramLocation = Omit<ProgramLocation, "id">;

const useAddLocation = () => {
  const addLocation = async (
    program_uuid: string,
    body: NewProgramLocation,
  ) => {
    console.log("Add location api called");
    console.log("program_uuid", program_uuid, "body", body);
    try {
      const response = await axiosInstance.post<
        any,
        AxiosResponse<ProgramLocation>,
        NewProgramLocation
      >(
        `${process.env.REACT_APP_API_URL}v2/business/location/add/${program_uuid}`,
        body,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Location updated", response.data);

      return response.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        console.error(
          "API Error:",
          error.response?.status,
          error.response?.data,
        );
      } else {
        console.error("Network Error:", error.message);
      }
      throw error;
    }
  };

  return addLocation;
};
export default useAddLocation;
