// hooks/useGetVoucherBankVouchers.js
import { useState, useEffect } from "react";
import { Voucher } from "../types";
import { getVoucherBank } from "../services/voucherBankService";

const useGetVoucherBankVouchers = (programId: number) => {
  const [voucherBankVouchers, setVoucherBankVouchers] = useState<Voucher[]>([]);

  useEffect(() => {
    const fetchVoucherBank = async () => {
      try {
        const data = await getVoucherBank(programId);
        setVoucherBankVouchers(data);
      } catch (error) {
        console.error("Error fetching voucher bank:", error);
      }
    };

    if (programId) {
      fetchVoucherBank();
    }
  }, [programId]);

  return { voucherBankVouchers };
};

export default useGetVoucherBankVouchers;
