import React, {
  useMemo,
  useState,
  useEffect,
  ReactNode,
  ChangeEventHandler,
  useRef,
} from "react";
import "./createloyalty_page.css";
import "./membership.css";
import "./qr-button.css";
import "./pass-modal.css";
import downloadQRcode from "./image/download2.png";
import crown from "./image/crown.png";
import Title from "../../Components/Title/title";
import Label from "../../Components/Label/label";
import { Col, ColorPicker, Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import useGetPrograms from "../../Hooks/getPrograms";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetVerified from "../../Hooks/getVerified";
import * as selectors from "../../selectors/selectors";
import { ReactComponent as SkipIcon } from "../../assets/symbols/skip.svg";
import * as actions from "../../actions/actions";
import LocationsModal from "../../Components/LocationModal/LocationModal";
import { warningWithTimeout, formatAddress } from "../../utils/helpers";
import {
  handleActionNotification,
  hexToRgb,
  isColorCloseToWhite,
  useShowTierLimitReachedWarning,
} from "../../utils/utils";
import Deal from "../../Components/Deal/deal";

import "./../../shared/plugins-overrides/geoapify.css";
import "./../../shared/plugins-overrides/_geo-apify-override.css";
import Place from "../../Components/Place/place";
import Explainer from "../../Components/Explainer/explainer";
import Tooltip from "../../Components/Tooltip/tooltip";
import GuidedTour from "../../Components/GuidedTour";
import Uploader from "../../Components/Uploader/uploader";

import AppButton from "../../Components/AppButton/app_button";
import TooltipContext from "../../Components/TooltipContext/tooltip_context";
import axiosInstance from "../../services/axiosIntercept";
import StepsNav from "../../Components/StepsNav/steps_nav";
import PatternPicker from "../../Components/PatternPicker/pattern_picker";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import OuterClose from "../../Components/OuterClose/outer_close";
import Pass from "../../Components/Pass/pass";
import TempHeroImage from "../../assets/temp/pass-image.jpg";
import TempQrImage from "../../assets/temp/qr.jpg";
import useGenerateStamps from "../../Hooks/generateStamps";
import AppInput from "../../Components/AppInput/app_input";
import useGetLocations from "../../Hooks/getLocation";
import { Program, ProgramLocation } from "../../types";
import useAddLocation from "../../Hooks/addLocation";
import { createUpdateProgram } from "../../services/programService";
import { isAxiosError } from "axios";
import { datadogRum } from "@datadog/browser-rum";
import classNames from "classnames";
import useLimitCheck from "../../Hooks/getLimitCheck";
import { getEmailList } from "../../services/getEmailList";
import { getQrCodeUrlForProgramUuid } from "../../utils/utils";

type Props = {
  id?: string;
  onSave: (data: Program) => void;
  handleSkipToOverview: () => void;
  handleCreateVoucherCampaign: () => void;
};

// Add this outside the component to avoid recreating on every render
const QR_CODE_PLACEHOLDER =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IndoaXRlIi8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTIwIDIwaDYwdjYwSDIwek0zNSAzNWgzMHYzMEgzNXoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMTIwIDIwaDYwdjYwaC02MHpNMTM1IDM1aDMwdjMwaC0zMHoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMjAgMTIwaDYwdjYwSDIwek0zNSAxMzVoMzB2MzBIMzV6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDkwaDIwdjIwSDkwek0xMjAgOTBoMjB2MjBoLTIwek0xNTAgOTBoMjB2MjBoLTIwek05MCAxMjBoMjB2MjBIOTB6TTEyMCAxMjBoMjB2MjBoLTIwek0xNTAgMTIwaDIwdjIwaC0yMHpNOTAgMTUwaDIwdjIwSDkwek0xMjAgMTUwaDIwdjIwaC0yMHpNMTUwIDE1MGgyMHYyMGgtMjB6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDIwaDIwdjIwSDkwek05MCA1MGgyMHYyMEg5MHpNMjAgOTBoMjB2MjBIMjB6TTUwIDkwaDIwdjIwSDUweiIvPgo8L3N2Zz4=";

function Createloyalty_page({
  id,
  onSave,
  handleSkipToOverview,
  handleCreateVoucherCampaign,
}: Props): ReactNode {
  const dispatch = useDispatch();
  const getLimitCheck = useLimitCheck();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  const uuid = useSelector(selectors.getAccountUuid);
  const selectedProgramId = useSelector(selectors.getSelectedLp);
  const hasAnyProgram = useSelector(selectors.getHasAnyProgram);
  const showTierVoucherLimitReachedWarning =
    useShowTierLimitReachedWarning("active_campaign");

  const getPrograms = useGetPrograms(uuid, selectedProgramId);
  const getVouchers = useGetVouchers(uuid);
  const getVerified = useGetVerified();
  const generateStamp = useGenerateStamps();
  const addLocation = useAddLocation();
  const locationLimit = useSelector((state: any) =>
    selectors.getLimitcheck(state),
  );
  const programs = useSelector(selectors.getProgram);
  const isPremium = useSelector(selectors.getAccountSubscription);
  const [data, setData] = useState<Program>();
  const [editProgram, setEditProgram] = useState(false);
  const [locations, setLocations] = useState<ProgramLocation[]>();

  const programUuid = useMemo(() => data?.uuid ?? "", [data?.uuid]);
  localStorage.setItem("programUuid", programUuid);
  const getLocations = useGetLocations(programUuid);
  const handleButtonClick = async (e: { preventDefault: () => void }) => {
    if (!programUuid) {
      e.preventDefault();
      return;
    }

    try {
      const response = await getEmailList(programUuid);
      //console.log("Email list response:", response);

      if (response.success) {
        notification.success({
          message: "Success",
          description: "Email list sent, please check your email.",
        });
      } else {
        notification.error({
          message: "Send email list fail",
          description: response.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error sending email list",
      });
    }
  };
  // console.log("Limits", locationLimit.voucherCampaignLimits);

  const locationAlert = locationLimit?.voucherCampaignLimits?.find(
    (limit) => limit.programId === selectedProgramId,
  );

  console.log("locationAlert", locationAlert);

  const numberOfLocationsLimitReached =
    (locations?.length || 0) >= (isPremium === 0 ? 1 : 1000) || false;
  // const numberOfLocationsLimitReached =
  //   locationAlert?.businessLocationAlert ||
  //   (locations?.length || 0) >=
  //     (isPremium === 0 ? 1 : locationLimit?.locationLimit || 0) ||
  //   false;
  const voucherAlert = locationAlert ? locationAlert.alert : false;

  console.log("Location Alert:", numberOfLocationsLimitReached);

  const locationsModalRef = useRef<{ openCreateModal: () => void }>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const [color, setColor] = useState(
    useSelector<any>((state) => state.common.colour),
  );
  const [isViewLocationModalVisible, setIsViewLocationModalVisible] =
    useState(false);
  const showTierLocationsLimitReachedWarning =
    useShowTierLimitReachedWarning("location");

  const showCreateModal = () => {
    if (numberOfLocationsLimitReached) {
      showTierLocationsLimitReachedWarning();
    } else {
      locationsModalRef.current?.openCreateModal();
    }
  };

  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [tAC, setTAC] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const currentSubscription = useSelector(selectors.getAccountSubscription);

  const [colorHex, setColorHex] = useState("#ffffff");

  const [selectedPattern, setSelectedPattern] = useState<string>();

  useEffect(() => {
    getLocations().then(setLocations);
  }, [getLocations]);

  useEffect(() => {
    if (id) {
      setData(programs?.find((x) => String(x.id) === id));
      setEditProgram(true);
    } else {
      setData(undefined);
      setEditProgram(false);
    }
  }, [id, programs]);

  const showModal = () => {
    setIsViewLocationModalVisible(true);
  };

  const [qrCodeUrl, setQrCodeUrl] = useState<string>(QR_CODE_PLACEHOLDER);
  const prevProgramUuid = useRef<string>("");
  const prevLocationId = useRef<string>("");

  useEffect(() => {
    // Only fetch if we have both programUuid and a location
    if (!programUuid || !locations?.[0]?.id) {
      setQrCodeUrl(QR_CODE_PLACEHOLDER);
      return;
    }

    // Skip if nothing changed
    if (
      programUuid === prevProgramUuid.current &&
      locations[0].id === prevLocationId.current
    ) {
      return;
    }

    // Update refs
    prevProgramUuid.current = programUuid;
    prevLocationId.current = String(locations[0].id);

    // Fetch QR code
    const fetchQrCodeUrl = async () => {
      try {
        const url = await getQrCodeUrlForProgramUuid(
          programUuid,
          String(locations?.[0]?.id),
        );
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Failed to fetch QR code:", error);
        setQrCodeUrl(QR_CODE_PLACEHOLDER);
      }
    };

    fetchQrCodeUrl();
  }, [programUuid, locations]);

  const [formatHex, setFormatHex] = useState<"hex" | "rgb" | "hsb">("hex");

  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [selectedImageSHA, setSelectedImageSHA] = useState<string>();
  const [heroImageSHA, setHeroImageSHA] = useState<string>();
  const [posterImageSHA, setPosterImageSHA] = useState<string>();

  const [isDiscoverable, setIsDiscoverable] = useState(
    /** @type {boolean} */ typeof data?.discoverable !== "undefined"
      ? Boolean(data.discoverable)
      : true,
  );
  const [isJoiningAllowed, setIsJoiningAllowed] = useState(
    /** @type {boolean} */ typeof data?.joiningAllowed !== "undefined"
      ? Boolean(data.joiningAllowed)
      : true,
  );

  const [istEmailOptInEnabled, setEmailOptInEnabled] = useState(
    /** @type {boolean} */ typeof data?.emailOptIn !== "undefined"
      ? Boolean(data.emailOptIn)
      : true,
  );

  const accountId = useSelector(selectors.getAccountId);
  const [industries, setIndustries] = useState<any[]>();

  const getIndustryName = () => {
    const industriesData = localStorage.getItem("Industries");
    if (!industriesData) return null;

    const industries: any[] = JSON.parse(industriesData);
    const industry = industries.find((c) => c.id === data?.productTypeId);
    return industry ? industry.name : null;
  };

  const [value_industry, setValue_industry] = useState<{
    id?: number;
    description?: string;
  }>({});

  const totalMobileSteps = 4;
  const [mobileStep, setMobileStep] = useState(0);
  const handleStepsNavPrevClick = () => {
    setMobileStep(Math.max(mobileStep - 1, 0));
  };

  const handleStepsNavNextClick = () => {
    setMobileStep(Math.min(mobileStep + 1, totalMobileSteps - 1));
  };

  const handleColorChange = (color: any) => {
    if (!color.metaColor.originalInput) {
      notification.info({
        message: "Please select your business brand color",
      });
    } else {
      //console.log("Color Picker Change:", color.metaColor.originalInput);
      dispatch({ type: "SET_COLOUR", payload: color.metaColor.originalInput });

      setColorHex(color);
      setSelectedColor(color);

      setColor(hexString);
    }
  };

  const handlePatternChange = (pattern: string) => {
    //console.log("Pattern Change:", pattern);
    dispatch({
      type: "SET_PATTERN",
      payload: pattern,
    });
    setSelectedPattern(pattern);
    // console.log(selectedPattern);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAuthenticated && !isLoggedOut) await getVerified();

        const ind_data = localStorage.getItem("Industries");

        if (!ind_data) {
          const industriesResponse = await getIndustry();
          setIndustries(industriesResponse);
          localStorage.setItem(
            "Industries",
            JSON.stringify(industriesResponse),
          );
        } else {
          setIndustries(JSON.parse(ind_data));
          // console.log(JSON.parse(ind_data))
        }

        if (data) {
          const ind = await getIndustry();

          if (ind) {
            ind.map((ind) => {
              if (ind.id === data.productTypeId) {
                setValue_industry({
                  id: ind.id,
                  description: ind.description,
                });
              }
            });
          }
        }
        setIsLoading(false); // Set loading to false after data is fetched, Sandeep
      } catch (error) {
        console.error("Error during data fetching: ", error);
      }
    };

    fetchData();
  }, [data]);

  const getIndustry = async (): Promise<any[] | undefined> => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}v1/product-type`,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );

      if (response.data) {
        setIndustries(response.data);

        return response.data;
      } else {
        console.error("No data received from the API");
        return undefined;
      }
    } catch (error) {
      if (isAxiosError(error)) {
        console.error(
          "API Error:",
          error.response?.status,
          error.response?.data,
        );
      } else {
        console.error("Network Error:", error);
      }

      return undefined;
    }
  };

  const handleIndustry: ChangeEventHandler<HTMLInputElement> = (event) => {
    const industrydescription = event.target.value;
    industries?.map((ind) => {
      if (ind.description === industrydescription) {
        setValue_industry({
          id: ind.id,
          description: ind.description,
        });
      }
    });
  };

  const hexString = useMemo(
    () =>
      typeof colorHex === "string" ? colorHex : (colorHex as any).toHexString(),
    [colorHex],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "bname":
        setBusinessName(value);
        break;
      case "descrip":
        setDescription(value);
        break;
      case "TAC":
        setTAC(value);
        break;
      default:
        break;
    }
  };

  /*   const handleAddressUpdate = (newAddress) => {
                                                                                                                                                                                                                                                                                                                        console.log("address handleAddressUpdate", newAddress);
                                                                                                                                                                                                                                                                                                                        setPhysicalAddress(newAddress);
                                                                                                                                                                                                                                                                                                                      };
  
                                                                                                                                                                                                                                                                                                                      const handlePlaceSelect = (value) => {
                                                                                                                                                                                                                                                                                                                        // You can access various details from value.properties
                                                                                                                                                                                                                                                                                                                        const address = value; // This gives the formatted address
                                                                                                                                                                                                                                                                                                                        console.log("address handlePlaceSelect", address);
                                                                                                                                                                                                                                                                                                                        setPhysicalAddress(address);
                                                                                                                                                                                                                                                                                                                      };
                                                                                                                                                                                                                                                                                                                     */

  /*   const handleSuggectionChange = (value) => {
                                                                                                                                                                                                                                                                                                                        // You can access various details from value.properties
                                                                                                                                                                                                                                                                                                                        const address = value; // This gives the formatted address
                                                                                                                                                                                                                                                                                                                        console.log("handleSuggectionChange", address);
                                                                                                                                                                                                                                                                                                                        setPhysicalAddress(address);
                                                                                                                                                                                                                                                                                                                      }; */

  useEffect(() => {
    setBusinessName(data?.name ?? "");
    setColor(data?.brandColor ?? "");
    setDescription(data?.description ?? "");
    setTAC(data?.termsNConditionsEmailOptIn ?? "");
    setAddressDetail(data?.QRCode ?? "");
    setColorHex(data?.brandColor ?? "#ffffff");
    setSelectedPattern(data?.brandPattern ?? "");
    setSelectedColor(data?.brandColor ?? "");
    setSelectedImageSHA(data?.imageURL ?? "");
    setHeroImageSHA(data?.heroImageId ?? "");
    setPosterImageSHA(data?.posterImageId ?? "");
    // setIsDiscoverable(
    //   typeof data?.discoverable !== "undefined"
    //     ? Boolean(data.discoverable)
    //     : true,
    // );
    setIsJoiningAllowed(
      typeof data?.joiningAllowed !== "undefined"
        ? Boolean(data.joiningAllowed)
        : true,
    );
    setEmailOptInEnabled(
      typeof data?.emailOptIn !== "undefined" ? Boolean(data.emailOptIn) : true,
    );

    setValue_industry({
      id: data ? data.productTypeId : undefined,
      description: data ? getIndustryName() : undefined,
    });
  }, [data]);

  const handleCreateOrUpdateLoyalty = () => {
    const ver_btn = document.getElementById("cr-btn-lp");
    // cr-btn-lp.disabled = true;
    (ver_btn as any).disabled = true; // TODO: Use local state instead

    if (!accountId) {
      notification.error({
        message: "Invalid Account",
        description: "Your account ID is invalid. Please log in again.",
      });
      dispatch(actions.logout());
      datadogRum.addError(new Error("accountId is invalid"));
      return;
    }

    //TODO first check all the values are filled or not.
    if (!businessName || businessName === "Your Brand") {
      warningWithTimeout({
        message: "Please enter the Brand Name!",
      });
      (ver_btn as any).disabled = false;
    } else if (!description) {
      warningWithTimeout({
        message: "Please enter the Description!",
      });
      (ver_btn as any).disabled = false;
    } else if (!locations?.length) {
      warningWithTimeout({
        message: "Please create at least one location for the program!",
      });
      (ver_btn as any).disabled = false;
    } else if (!value_industry.id) {
      warningWithTimeout({
        message: "Please select the Industry!",
      });
      (ver_btn as any).disabled = false;
    } else if (
      !selectedColor &&
      (selectedColor as any).metaColor &&
      (selectedColor as any).metaColor.originalInput
    ) {
      warningWithTimeout({
        message: "Please select the color!",
      });
      (ver_btn as any).disabled = false;
    } else {
      //console.log(selectedImageSHA);
      if (selectedImageSHA) {
        var body: Program = {
          name: businessName,
          description: description,
          imageURL: selectedImageSHA,
          heroImageId: heroImageSHA!,
          posterImageId: posterImageSHA!,
          productTypeId: value_industry.id,
          QRCode: JSON.stringify(addressDetail),
          physicalAddressDetail: addressDetail,
          brandColor:
            selectedColor &&
            (selectedColor as any).metaColor &&
            (selectedColor as any).metaColor.originalInput
              ? hexString
              : data
                ? data.brandColor
                : `#ffffff`,
          brandPattern: selectedPattern!,
          cityId: 1,
          stateId: 1,
          countryId: 1,
          accountId: accountId,
          discoverable: false,
          joiningAllowed: isJoiningAllowed,
          emailOptIn: istEmailOptInEnabled,
          termsNConditionsEmailOptIn: tAC,
        };

        if (data && data.id) {
          // If we're editing a program, send back the id to the API
          (body as any).id = data.id;
        }
        //console.log("body", body);
        createUpdateProgram(body)
          .then(async (response) => {
            notification.success({
              message: editProgram
                ? "Loyalty program updated successfully"
                : "Loyalty program created successfully",
              description: `${businessName} loyalty program ${
                editProgram ? "updated" : "created"
              } `,
            });

            generateStamp(response.id, editProgram, response.uuid);

            // if (editProgram) {
            //   // console.log("ProgramData", data);
            //   setTimeout(() => {
            //     // updatePasses(data.uuid);
            //   }, 1000); // Delay of 1 seconds
            // }

            dispatch({ type: "SET_COLOUR", payload: "#ffffff" });
            dispatch({ type: "SET_PATTERN", payload: "" });

            onSave(response);

            //console.log("Loyalty program updated successfully:", response);

            const programUuid = String(response.uuid);
            dispatch(actions.setSelectedLp(response.id));
            dispatch(actions.setSelectedLpUuid(programUuid));

            const locationsToCreate = locations
              .filter((x) => String(x.id).startsWith("_"))
              .map(({ id, ...rest }) => rest);

            const createdLocations = await Promise.all(
              locationsToCreate.map((x) => addLocation(programUuid, x)),
            );

            //console.log(
            //  "locationToCreate after program create",
            //  locationsToCreate,
            //  createdLocations,
            //);

            // Combine list of existing locations with newly created locations
            setLocations((state) =>
              (state || [])
                .filter((x) => !x.id.toString().startsWith("_"))
                .concat(createdLocations),
            );

            getPrograms();
            getVouchers(response.id);

            (ver_btn as any).disabled = false;
          })
          .catch((error) => {
            console.error("Error updating loyalty program:", error);
            notification.error({
              message: "Error while creating Loyalty program",
              description: `Failed to create Loyalty program. Please try again.`,
            });

            (ver_btn as any).disabled = false;
          });
      } else {
        warningWithTimeout({
          message: "Please upload Logo",
          description:
            "Without the logo, we cannot start the loyalty program. Please try to create loyalty program after uploading the Logo.",
        });

        (ver_btn as any).disabled = false;
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="membership container">
      <h1 className="membership__heading">
        <Title>Your loyalty program</Title>
      </h1>
      <div className="membership__body">
        <section
          className={`membership__section${
            mobileStep === 0 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>About your Loyalty Program</Title>
          </h2>
          <div className="membership__widget">
            <Label>Name your Loyalty Program</Label>
            <AppInput
              id="bname"
              name="bname"
              placeholder="Your business name"
              value={businessName}
              onChange={handleInputChange}
              style={{ color: "#5D6164", fontSize: "15px" }}
            />
          </div>
          <div className="membership__widget">
            <Label>
              Introduce your business
              <Explainer size="20">
                Write a paragraph or two about
                <br />
                your business. This will appear on
                <br />
                the digital membership card under
                <br />
                your business name.
              </Explainer>
            </Label>
            <AppInput
              tag="textarea"
              id="descrip"
              name="descrip"
              placeholder="Tell us about your business (Max 2000 characters)"
              rows="10"
              value={description}
              onChange={handleInputChange}
              style={{ color: "#5D6164", fontSize: "15px" }}
            />
          </div>
          <div className="membership__widget">
            <Label>Industry</Label>
            <AppInput
              tag="select"
              name="industry"
              value={value_industry.description}
              onChange={handleIndustry}
              selectOptions={industries}
              selectPlaceholder="Select your industry"
              style={{ color: "#5D6164", fontSize: "15px" }}
            />
          </div>

          <div className="membership__widget">
            <Label>Location</Label>
          </div>
          <AppButton
            variant="secondary"
            className={classNames(
              "location-btn",
              numberOfLocationsLimitReached && "button--disabled",
            )}
            onClick={showCreateModal}
          >
            Enter new Location
          </AppButton>
          <AppButton
            variant="secondary"
            className="location-btn"
            onClick={showModal}
            disabled={locations?.length === 0}
          >
            View all Locations
          </AppButton>

          <LocationsModal
            open={isViewLocationModalVisible}
            locations={locations}
            onChange={(locations) => {
              setLocations(locations);
              getLimitCheck();
            }}
            onClose={() => {
              setIsViewLocationModalVisible(false);
            }}
            programUuid={programUuid}
            numberOfLocationsLimitReached={numberOfLocationsLimitReached}
            ref={locationsModalRef}
          />
          {/* <div className="geoapify">
            <LocationAutocomplete
              onAddressSelect={(address) => {
                setPhysicalAddress(address);
              }}
              onGooglePlacesDetailsPopulated={(addressDetail) => {
                setAddressDetail(JSON.stringify(addressDetail));
              }}
            />
            {physicalAddress && (
              <div className="geoapify__output">
                {formatAddress(physicalAddress)}
              </div>
            )}
          </div> */}
        </section>
        <section
          className={`membership__section${
            mobileStep === 1 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>Visual Assets and Settings</Title>
            {/* <Explainer size="20">
              The preview section shows the front and
              <br />
              back of the digital membership card as
              <br />
              your customers see it in their phones.
            </Explainer> */}
          </h2>
          {/* <div className="membership__description">
            This is the membership card your customers will see when they join
            your loyalty program.
          </div> */}
          <div className="membership__campaign-preview">
            {/* <div className="membership__campaign-heading">
              <Label>Full view</Label>
            </div>
            <div className="membership__campaign-preview-widget">
              <BrandCard
                logoPlaceHolder={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                industry={value_industry.description}
                location={formatAddress(locations?.[0]?.address)}
                description={description}
                physicalAddressDetailString={data?.physicalAddressDetail ?? ""}
                digitalVoucherCount={0}
              />
            </div> */}
            <div className="membership__widget">
              <Label>Loyalty Program Logo</Label>
              <Uploader
                type="image"
                width={315}
                height={315}
                message="Upload your Loyalty Program logo"
                defaultImageSrc={data?.imageURL}
                onUploaded={(sha) => {
                  setSelectedImageSHA(sha);
                }}
                aspectRatio={1}
              />
              <p style={{ color: "#5D6164", fontSize: "12px", marginTop: "0" }}>
                Image size must be below 2MB
              </p>
            </div>
            <div className="membership__widget">
              <Label>Hero Image for Apple/Google Pass</Label>
              <Uploader
                type="image"
                width={312}
                height={126}
                message="Upload your hero image"
                defaultImageSrc={data?.heroImageId}
                onUploaded={(sha) => {
                  setHeroImageSHA(sha);
                }}
                aspectRatio={2.47619048 / 1}
              />
            </div>
            <div className="membership__widget">
              <Label>Image for generating poster</Label>
              <Uploader
                type="image"
                width={500}
                height={500}
                message="Upload your image"
                defaultImageSrc={data?.heroImageId}
                onUploaded={(sha) => {
                  setPosterImageSHA(sha);
                }}
                aspectRatio={1 / 1}
              />
            </div>
            <div className="membership__settings">
              <Label>Settings</Label>
              {/* <div className="param param--larger">
                <div className="param__label">
                  Discoverable
                  <Explainer>
                    To make the loyalty program discoverable
                  </Explainer>
                </div>
                <div className="param__widget">
                  <Switch
                    size="small"
                    checked={isDiscoverable}
                    onChange={() => {
                      setIsDiscoverable((state) => !state);
                    }}
                    style={{
                      background: isDiscoverable ? "var(--orange)" : undefined,
                      borderColor: isDiscoverable ? "var(--orange)" : undefined,
                    }}
                  />
                </div>
              </div> */}
              <div className="param param--larger">
                <div className="param__label">
                  Joinable
                  <Explainer>To allow members to join this program</Explainer>
                </div>
                <div className="param__widget">
                  <Switch
                    size="small"
                    checked={isJoiningAllowed}
                    onChange={() => {
                      setIsJoiningAllowed((state) => !state);
                    }}
                    style={{
                      background: isJoiningAllowed
                        ? "var(--orange)"
                        : undefined,
                      borderColor: isJoiningAllowed
                        ? "var(--orange)"
                        : undefined,
                    }}
                  />
                </div>
              </div>
              {/* <div className="param param--larger">
                <div className="param__label">
                  Email Opt in
                  <Explainer>
                    To allow members accept sharing email with this program
                  </Explainer>
                  {currentSubscription === 0 && (
                    <img
                      src={crown}
                      style={{
                        background: istEmailOptInEnabled
                          ? "var(--orange)"
                          : undefined,
                        borderColor: istEmailOptInEnabled
                          ? "var(--orange)"
                          : undefined,
                      }}
                    />
                  )}
                </div>
                <div className="param__widget">
                  <Switch
                    size="small"
                    checked={
                      currentSubscription !== 0
                        ? istEmailOptInEnabled || false
                        : false
                    }
                    disabled={currentSubscription === 0}
                    onChange={() => {
                      setEmailOptInEnabled((state) => !state);
                    }}
                    style={{
                      background: istEmailOptInEnabled
                        ? "#FF7B0F"
                        : "undefined",
                      borderColor: istEmailOptInEnabled
                        ? "#FF7B0F"
                        : "undefined",
                    }}
                  />
                </div>
              </div>
              {currentSubscription !== 0 && (
                <div className="membership__widget">
                  <Label>Organisation Privacy Policy</Label>
                  <AppInput
                    id="TAC"
                    name="TAC"
                    placeholder="Enter link to your Terms of Use"
                    value={tAC}
                    onChange={handleInputChange}
                    style={{ color: "#5D6164", fontSize: "15px" }}
                  />
                </div>
              )} */}
            </div>
          </div>
        </section>
        <section
          className={`membership__section${
            mobileStep === 2 ? " membership__section--mobile-current" : ""
          }`}
        >
          <h2 className="membership__sub-heading">
            <Title secondary>Voucher Colour</Title>
            <Explainer size="20">
              All vouchers have a spot colour.
              <br />
              Choose your main brand colour,
              <br />
              or something different!
              <br />
            </Explainer>
          </h2>
          <div className="membership__description">
            Please choose the base colour for the vouchers you send to you
            customers. You can use your primary brand color or any other color
            you prefer
          </div>
          <div className="membership__split-widget">
            <div className="membership__half">
              <Label smaller>Select your colour</Label>
              <div className="color-picker">
                <ColorPicker
                  disabledAlpha
                  open={colorPickerOpen}
                  onOpenChange={setColorPickerOpen}
                  format={formatHex}
                  value={colorHex}
                  onChange={handleColorChange}
                  onFormatChange={(newFormat) => {
                    setFormatHex(newFormat);
                    setColor(hexString);
                  }}
                  styles={{
                    popupOverlayInner: {},
                  }}
                  panelRender={(_, { components: { Picker, Presets } }) => (
                    <div className="custom-dropdown">
                      <Picker />
                      <div className="custom-dropdown__close">
                        <OuterClose
                          onClick={() => {
                            setColorPickerOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
                <Col className="color-code">
                  <span>{hexString}</span>
                </Col>
              </div>
            </div>
            <div className="membership__half">
              <Label smaller>Select your pattern</Label>
              <PatternPicker
                prevPattern={data ? data.brandPattern : ""}
                onPatternSelect={handlePatternChange}
              />
            </div>
          </div>
          <div className="membership__preview">
            <h3 className="membership__preview-heading">
              <Title secondary>Preview</Title>
            </h3>
            {/* <div className="membership__voucher-preview">*/}
            <div className="membership__voucher">
              <Place
                backgroundColor={hexString}
                textColor={
                  isColorCloseToWhite(hexToRgb(hexString), 50)
                    ? "#000000"
                    : "#FFFFFF"
                }
                brandLogo={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                location={formatAddress(locations?.[0]?.address)}
                industry={value_industry.description}
                digitalVoucherCount={0}
                onArrowChange={() => {
                  setIsModalOpen(false);
                  {
                    /* Change this to true to open modal */
                  }
                }}
              />
              {/* Pass in modal: */}
              <Modal
                open={isModalOpen}
                onCancel={() => {
                  setIsModalOpen(false);
                }}
                footer={null}
                className={"pass-modal"}
              >
                <Pass
                  brandLogo={
                    selectedImageSHA
                      ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                      : undefined
                  }
                  brandName={businessName}
                  location={formatAddress(locations?.[0]?.address)}
                  heroImage={TempHeroImage}
                  customerName={"Massimo"}
                  customerId={"C13579246810"}
                  memberSince={"2018"}
                  qrCodeImage={TempQrImage}
                  qrCodeNumber={"11223344556677"}
                />
              </Modal>
            </div>
            <div className="membership__voucher">
              {/*  <div
                className="bg-preview"
                style={{ backgroundColor: hexString }}
              ></div>

              <div className="cl-preview-card3">
                <div
                  className="cl-card-image3"
                  style={{
                    borderRadius: 10,
                    backgroundColor: selectedImageSHA ? "transparent" : "lightgray",
                  }}
                >
                  {selectedImageSHA && (
                    <img
                      alt="not found"
                      width={"100%"}
                      style={{
                        // maxHeight: "150px", width: "100%", height: "100%"
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        borderRadius: 10,
                      }}
                      src={
                        selectedImageSHA != null &&
                        `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                      }
                    />
                  )}
                </div>
                <div className="card-title" style={{ color: "black" }}>
                  {businessName}
                  <div className="card-subtitle">
                    <div className="icon"></div>
                    {value_city?.name}
                  </div>
                </div>
              </div> */}
              {/* <div className='cl-preview-card'></div> */}
              <Deal
                background={hexString}
                brandLogo={
                  selectedImageSHA
                    ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                    : undefined
                }
                brandName={businessName}
                offerValue="15% OFF"
                offerTitle="Discount Offer"
                location={formatAddress(locations?.[0]?.address)}
                duration="0"
              />
            </div>
          </div>
          <div className="membership__submit">
            <AppButton
              fullWidth
              uniqueModifier="submit"
              id="cr-btn-lp"
              onClick={() =>
                handleActionNotification(
                  handleCreateOrUpdateLoyalty,
                  editProgram
                    ? "Would you like to update the loyalty program?"
                    : "Would you like to create the loyalty program?",
                  "Cancel",
                  "Yes",
                )
              }
            >
              {editProgram && (
                <span>
                  Update&nbsp;
                  <br className="membership__submit-line-break" />
                  Loyalty Program
                </span>
              )}
              {!editProgram && (
                <span>
                  Create&nbsp;
                  <br className="membership__submit-line-break" />
                  Loyalty Program
                </span>
              )}
            </AppButton>
          </div>
        </section>
        <section
          className={`membership__section${
            mobileStep === 3 ? " membership__section--mobile-current" : ""
          }`}
        >
          <div className="loyalty-program-qr-code">
            <h2 className="membership__sub-heading">
              <Title secondary>QR code</Title>
              <Explainer size="20">
                To join your loyalty program, meed
                <br />
                uses QR Code. Our platform will
                <br />
                generate one for you.
              </Explainer>
            </h2>
            <div className="membership__qr-code">
              <div className="membership__preview-qr">
                {!editProgram && (
                  <div className="membership__qr-overlay">Your QR Code</div>
                )}
                <img
                  className={`membership__qr-image ${
                    !programUuid ? "membership__qr-image--blur" : ""
                  }`}
                  src={qrCodeUrl}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="membership__download-qr-code">
            <TooltipContext>
              <p>This is the QR code of your first location.</p>
              {/* <AppButton
                asLink
                href={programUuid ? qrCodeUrl : ""}
                rel="noopener noreferrer"
                target="_blank"
                fullWidth
                uniqueModifier="download-qr"
                disabled={!programUuid}
                onClick={(e) => {
                  if (!programUuid) e.preventDefault();
                }}
              >
                <img src={downloadQRcode} style={{ width: 48 }}></img>
                Download your
                <br /> business QR code
              </AppButton> */}

              <Tooltip>
                This is your loyalty program QR code. Print it out
                <br /> and display it so customers can join your program
                <br />
                by scanning it with their phones.
              </Tooltip>
            </TooltipContext>
          </div>
          {/* {currentSubscription != 0 && (
            <div className="membership__download-qr-code">
              <TooltipContext>
              <AppButton
                fullWidth
                uniqueModifier="download-qr"
                disabled={!programUuid}
                onClick={handleButtonClick}
              >
                <img src={downloadQRcode} style={{ width: 48 }}></img>
                Export member list
              </AppButton>
              <Tooltip>
        This is your loyalty program QR code. Print it out
        <br /> and display it so customers can join your program
        <br />
        by scanning it with their phones.
        </Tooltip>
              </TooltipContext>
            </div>
          )} */}
          <div className="membership__create-voucher">
            <TooltipContext>
              <AppButton
                fullWidth
                uniqueModifier="create"
                className="create-voucher-btn tooltip-container"
                id="cr-btn-cvc"
                onClick={() => {
                  handleCreateVoucherCampaign();
                  if (voucherAlert) {
                    showTierVoucherLimitReachedWarning();
                  }
                }}
                disabled={voucherAlert || !hasAnyProgram}
              >
                Create a<br /> voucher campaign
              </AppButton>
              <Tooltip>
                Click this button to create a
                <br /> voucher campaign to send to all
                <br /> your loyalty program members.
              </Tooltip>
            </TooltipContext>
          </div>

          <div className="membership__skip-voucher">
            <AppButton
              fullWidth
              variant="outline"
              uniqueModifier="skip"
              id="cr-btn-cvc"
              onClick={() => handleSkipToOverview()}
              disabled={!hasAnyProgram}
              rightIcon={SkipIcon}
            >
              Skip
            </AppButton>
          </div>
        </section>
      </div>
      <div className="membership__navigation">
        <StepsNav
          current={mobileStep}
          total={totalMobileSteps}
          prevClickHandler={handleStepsNavPrevClick}
          nextClickHandler={handleStepsNavNextClick}
        />
      </div>
      <GuidedTour
        id="createLoyaltyProgram"
        steps={[
          {
            content:
              "Write a paragraph or two about your business. This will appear on the digital membership card under your business name.",
            title: "Step 1",
            target: ".membership__section:nth-child(1)",
            placementBeacon: "top",
            placement: "right",
          },
          {
            content:
              "The preview section shows the front and back of the digital membership card as your customers see it in their phones.",
            title: "Step 2",
            target: ".membership__section:nth-child(2)",
            placementBeacon: "top",
            placement: "right",
          },
          {
            content:
              "All vouchers have a spot colour. Choose your main brand colour, or something different!",
            title: "Step 3",
            target: ".membership__section:nth-child(3)",
            placementBeacon: "top",
            placement: "left",
          },
          {
            content:
              "To join your loyalty program, meed uses QR Code. Our platform will generate one for you.",
            title: "Step 4",
            target: ".loyalty-program-qr-code",
            placementBeacon: "top-end",
            placement: "bottom-start",
          },
          {
            content:
              "This is your loyalty program QR code. Print it out and display it so customers can join your program by scanning it with their phones.",
            title: "Step 5",
            target: ".membership__download-qr-code",
            placementBeacon: "top-end",
            placement: "bottom-start",
          },
          {
            content:
              "Click this button to create a voucher campaign to send to all your loyalty program members.",
            title: "Step 6",
            target: ".create-voucher-btn",
            placementBeacon: "top-end",
            placement: "left",
          },
        ]}
      />
    </div>
  );
}

export default Createloyalty_page;
