import axiosInstance from "../services/axiosIntercept";

const useGetPaymentStatus = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/stripe/session?sessionId=${sessionId}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    // console.log("response", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default useGetPaymentStatus;
