import React, { useEffect, useMemo, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import axiosInstance from "../../services/axiosIntercept";
import "./looker.css";
import {
  createDashboardURL,
  DASHBOARD_ID,
  DASHBOARD_URL,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import {
  getAccountName,
  getAccountUuid,
  getSelectedLpUuid,
  getSelectedProgramName,
} from "../../selectors/selectors";
import Menu from "../../Components/Looker/menu";
import Analytics from "../../Pages/Analytics/analytics"; // Import Analytics component
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import useGetLocations from "../../Hooks/getLocation";

const LookerEmbed = ({ dashboardInfo }: { dashboardInfo: any }) => {
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const apiKey = process.env.REACT_APP_API_KEY;

  const programName = useSelector(getSelectedProgramName);
  const programUuid = useSelector(getSelectedLpUuid);
  const accountName = useSelector(getAccountName);
  const accountUuid = useSelector(getAccountUuid);
  const getLocations = useGetLocations(programUuid);

  useEffect(() => {
    if (
      dashboardInfo.dashboardId === null ||
      dashboardInfo.dashboardUrl === null
    ) {
      return;
    }

    const fetchEmbedUrl = async () => {
      try {
        setIsLoading(true);
        if (!process.env.REACT_APP_API_URL) {
          throw new Error("API URL is not defined");
        }

        if (!accountUuid || !programName) {
          return;
        }
        const locationNames = (await getLocations()).map((x) => x.locationName);

        const dashboardUrl = await createDashboardURL(
          dashboardInfo.dashboardUrl,
          accountUuid,
          accountName,
          programName,
          locationNames,
        );

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}v1/looker/signed-url`,
          {
            dashboardId: dashboardInfo.dashboardId,
            dashboardUrl: dashboardUrl,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": apiKey,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data) {
          setEmbedUrl(response.data.signedUrl);
        } else {
          console.error("No embed URL found in response.");
        }
      } catch (error) {
        console.error("Error fetching embed URL:", error);
      } finally {
        setIsLoading(false);
      }
    };
    // setEmbedUrl('https://meed.cloud.looker.com/login/embed/%2Fembed%2Fdashboards%2F24%3FOrganisation%2BName%3DPhil%2BIngram%26Program%2BName%3DGraceland%26Organisation%2BUUID%3D630379bb-a5da-4ce7-bb0d-b187dc848e18%26Date%2BRange%3D7%2Bday%26Location%3DMain%2BLocation?nonce=%22lcM6d17z9cY5Eoec%22&time=1726131557&session_length=1800&external_user_id=%2210%22&permissions=%5B%22see_user_dashboards%22%2C%22access_data%22%2C%22see_looks%22%5D&models=%5B%22meed_loyalty%22%5D&access_filters=%7B%7D&user_attributes=%7B%7D&force_logout_login=true&signature=aXSMz%2Bkxt3s8hXyJzzVd929zZoU%3D');
    fetchEmbedUrl();
  }, [
    getLocations,
    accountName,
    accountUuid,
    programName,
    dashboardInfo,
    apiKey,
  ]);

  useEffect(() => {
    if (
      embedUrl &&
      dashboardRef.current &&
      process.env.REACT_APP_LOOKER_EMBED_HOST
    ) {
      try {
        dashboardRef.current.innerHTML = "";
        LookerEmbedSDK.init(process.env.REACT_APP_LOOKER_EMBED_HOST, "/auth");

        LookerEmbedSDK.createDashboardWithUrl(embedUrl)
          .appendTo(dashboardRef.current)
          .withClassName("looker-dashboard")
          .on("dashboard:run:start", () => console.log("Dashboard run started"))
          .on("dashboard:run:complete", () =>
            console.log("Dashboard run completed"),
          )
          .build()
          .connect()
          .then(() => {
            console.log("Dashboard embedded successfully");
          })
          .catch((error) => {
            console.error("Error connecting the dashboard:", error);
          });
      } catch (error) {
        console.error("Error during Looker Embed SDK initialization:", error);
      }
    }
  }, [embedUrl]);

  return isLoading ? (
    <div style={{ height: "50vh" }}>
      <LoadingSpinner />
    </div>
  ) : (
    <div ref={dashboardRef}></div>
  );
};

function getLookerDashboardInfoFromSectionString(section?: string): {
  dashboardId: number | null;
  dashboardUrl: string | null;
} {
  switch (section) {
    case "voucher":
      return {
        dashboardId: DASHBOARD_ID.VOUCHER,
        dashboardUrl: DASHBOARD_URL.VOUCHER,
      };
    case "stamp-card":
      return {
        dashboardId: DASHBOARD_ID.STAMP_CARD,
        dashboardUrl: DASHBOARD_URL.STAMP_CARD,
      };
    case "milestone-card":
      return {
        dashboardId: DASHBOARD_ID.MILESTONE_CARD,
        dashboardUrl: DASHBOARD_URL.MILESTONE_CARD,
      };
    case "location":
      return {
        dashboardId: DASHBOARD_ID.QRC_LOCATION,
        dashboardUrl: DASHBOARD_URL.QRC_LOCATION,
      };
    case "membership":
      return {
        dashboardId: DASHBOARD_ID.MEMBERSHIP,
        dashboardUrl: DASHBOARD_URL.MEMBERSHIP,
      };
    default:
      return {
        dashboardId: DASHBOARD_ID.OVERVIEW,
        dashboardUrl: DASHBOARD_URL.OVERVIEW,
      };
  }
}

function Looker({ section }: { section?: string }) {
  const dashboardInfo = useMemo(
    () => getLookerDashboardInfoFromSectionString(section),
    [section],
  );

  const showAnalytics = useMemo(() => section === "performance", [section]);

  console.log("Dashboard Info:", dashboardInfo);
  return (
    // <Dashboard>
    <div style={{ flex: 1 }}>
      <Menu section={section} />
      <div className="dashboard-content">
        {showAnalytics ? (
          <Analytics />
        ) : (
          <LookerEmbed dashboardInfo={dashboardInfo} />
        )}
      </div>
    </div>

    // {/* </Dashboard> */}
  );
}

export default Looker;
