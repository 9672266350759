import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Modal, Progress, ColorPicker } from "antd";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { DeviceUUIDComponent } from "../../device_id";
import axiosInstance from "../../services/axiosIntercept";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import AppInput from "../../Components/AppInput/app_input";
import Label from "../../Components/Label/label";
import Uploader from "../../Components/Uploader/uploader";
import "./onBoardingForm.css";
import { verifyOtp } from "../../services/verificationService";
import { createUpdateProgram } from "../../services/programService";
import useAddOrganization from "../../Hooks/addOrganization";
import { DayPicker } from "react-day-picker";
import { DateTime } from "luxon";
import useAddLocation from "../../Hooks/addLocation";
import useGetVerified from "../../Hooks/getVerified";
import useAddAccountName from "../../Hooks/addAccountName";
import useUpdateProfile from "../../Hooks/updateProfile";
import useGetOrganizations from "../../Hooks/getOrganizations";
import { useNavigate } from "react-router-dom";
import meedLogo from "./icon/meed square logo-orange 07.png";

type FormData = {
  firstName: string;
  email: string;
  otp: string;
  businessName: string;
  description: string;
  address: string;
  offerType: "percentage" | "amount";
  offerValue: string;
  offerDescription: string;
  expiryDays: string;
  logo: string;
  heroImage: string;
  startDate: Date | null;
  endDate: Date | null;
  voucherImage: string;
  brandColor: string;
};

type ProgressStep = {
  title: string;
  status: "pending" | "processing" | "success" | "error";
};

// Define step titles and descriptions
const STEP_TITLES = [
  "Verify your email",
  "Tell us about your business",
  "Where are you located?",
  "Upload your branding",
  "Create your first offer",
  "Design your voucher",
  "Set campaign schedule",
];

const STEP_DESCRIPTIONS = {
  0: `Please enter the email address to log on to the platform.

You can edit anything you enter here later.

Your account will be active when you finish this wizard, and your free voucher will be live. After we review it, we will make it public for marketing.

Also, please have three images ready:

• Your logo, either in square format or able to be cropped to a square without breaking the image
• An image for an Apple or Google pass, which will crop to approximately 3:1
• An image for your special offer, which will crop to a square.

NOTE: Please do not use this form if you already have a meed account with this email.`,
  1: `Enter the business name, such as the restaurant's name, as customers see when they enter.

Then, Write a description of your restaurant, which will be accessible via the customer's digital membership card.

You can change these later.`,
  2: `Please enter your address in one line and leave "Hong Kong", as this will be set automatically. For example:

1F, 100 Made Up Road, Wanchai

Note: This form is only for F&B outlets in Hong Kong`,
  3: `Upload the logo for your restaurant, bar or cafe. It will be cropped to a square.

Then, upload an image to go on your Apple & Google Pass. This image is approximately 3:1 after cropping.`,
  4: `First, Choose your offer type. You can choose between a percentage discount or a cash amount.

Enter the value (either a percentage or cash amount)

Then, describe the voucher. For example:

Take HK$100 off your bill with this voucher on your next trip to Freddo's.`,
  5: `Upload the image that will appear in your voucher. This image will crop to a square.`,
  6: `The start date is already set for today. Navigate the calendar and set the expiry date for the voucher. For your first offer using the free tier of meed, we recommend two to three months from the current date.`,
};

// Add type definition
type Organization = {
  account?: {
    name?: string;
  };
};

export default function OnBoardingForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const turnstileRef = useRef<TurnstileInstance | null>(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addOrganization = useAddOrganization();
  const [showProgress, setShowProgress] = useState(false);
  const [progressSteps, setProgressSteps] = useState<ProgressStep[]>([
    { title: "Creating Account", status: "pending" },
    { title: "Creating Organization", status: "pending" },
    { title: "Setting up Loyalty Program", status: "pending" },
    { title: "Setting up Location", status: "pending" },
    { title: "Creating Welcome Voucher", status: "pending" },
  ]);

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    email: "",
    otp: "",
    businessName: "",
    description: "",
    address: "",
    offerType: "percentage",
    offerValue: "",
    offerDescription: "",
    expiryDays: "30",
    logo: "",
    heroImage: "",
    startDate: new Date(),
    endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    voucherImage: "",
    brandColor: "#333333",
  });

  const userId = useSelector(selectors.getId);
  const accountUuid = useSelector(selectors.getAccountUuid);
  const accountId = useSelector(selectors.getAccountId);
  const addLocation = useAddLocation();
  const getVerified = useGetVerified();
  const addAccountName = useAddAccountName();
  const updateProfile = useUpdateProfile();
  const getOrganizations = useGetOrganizations();
  const organizations = useSelector(selectors.getAccounts);

  const handleSendOTP = async () => {
    try {
      setIsSubmitting(true);
      const login_btn = document.getElementById(
        "login-btn-dis",
      ) as HTMLButtonElement;
      if (login_btn) {
        login_btn.disabled = true;
      }

      // Email validation
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(formData.email)) {
        notification.error({
          message: "Invalid Email",
          description: "Please enter a valid email address.",
        });
        if (login_btn) {
          login_btn.disabled = false;
        }
        return;
      }

      if (!turnstileRef.current) {
        throw new Error("Turnstile not initialized");
      }

      const deviceUUID = DeviceUUIDComponent();
      const turnstileToken = await turnstileRef.current.getResponse();

      if (!turnstileToken) {
        notification.error({
          message: "Captcha Validation Failed",
          description: "Please complete the captcha challenge.",
        });
        if (login_btn) {
          login_btn.disabled = false;
        }
        return;
      }

      notification.info({
        message: "Sending the OTP",
        description: `Please wait...`,
      });

      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v5/auth/portal/getOTP`,
        {
          email: formData.email,
          deviceID: deviceUUID,
          turnstileToken,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );

      setIsOtpSent(true);
      notification.info({
        message: "You will receive the OTP in a moment",
        description: `OTP has been sent to the email address ${formData.email}`,
      });
      dispatch(actions.setEmail(formData.email));
    } catch (error) {
      console.error(error);
      notification.warning({
        message: (
          <>
            Business portal is in beta and only accessible to those who have
            been granted access. Please join the waitlist to get access here{" "}
            <a href="https://www.meedloyalty.com" target="_blank">
              https://www.meedloyalty.com
            </a>
          </>
        ),
      });
    } finally {
      setIsSubmitting(false);
      const login_btn = document.getElementById(
        "login-btn-dis",
      ) as HTMLButtonElement;
      if (login_btn) {
        login_btn.disabled = false;
      }
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setIsSubmitting(true);
      const deviceUUID = DeviceUUIDComponent();

      const response = await verifyOtp({
        email: formData.email,
        otpCode: formData.otp,
        deviceID: deviceUUID,
      });

      if (!response.account) {
        dispatch(
          actions.setAuthError("Organization for the user is not found!"),
        );
        notification.error({
          message: "Authentication Failed. Please try again.",
        });
        return;
      }

      // Set User Data first
      dispatch(actions.setName(formData.firstName));
      dispatch(actions.setId(response.merchantUser.id));
      dispatch(actions.setUuid(response.merchantUser.uuid));
      dispatch(actions.setProfileImage(response.merchantUser.profileImageId));

      // Check if user already has organizations
      const userUuid = response.merchantUser.uuid;
      if (userUuid) {
        // Make the request directly with userUuid from response
        const orgResponse = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}v2/account/list`,
          { userUuid },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        );

        const existingOrganizations = orgResponse.data;
        console.log("Organizations:", existingOrganizations);

        // Check if user has any organization other than "...no name..."
        const hasRealOrganization = existingOrganizations?.some(
          (org: Organization) => org.account?.name !== "...no name...",
        );

        if (hasRealOrganization) {
          try {
            const response = await axiosInstance.get(
              `${process.env.REACT_APP_API_URL}v3/auth/business/portal/verify`,
              {
                withCredentials: true,
                headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
                },
              },
            );

            if (response.data.authenticated) {
              dispatch(actions.setIsAuthenticated());
              notification.info({
                message: "You are already Logged in",
              });
              navigate("/dashboard", { replace: true });
            }
          } catch (error) {
            console.error("Error during data fetching: ", error);
          }
        }
      }

      // Continue with new user flow if no organizations or no account
      const accounts = Array.isArray(response.account)
        ? response.account
        : [response.account];

      if (accounts.length > 0) {
        dispatch(actions.setAccounts(accounts));
        if (response.merchantUser.defaultAccount) {
          dispatch(actions.setAccount(response.merchantUser.defaultAccount));
        } else if (accounts[0]) {
          dispatch(actions.setAccount(accounts[0]));
        }
      }

      setIsOtpVerified(true);
      setCurrentStep(1);

      notification.success({
        message: "Verification Successful",
        description: "Your email has been verified successfully.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setShowProgress(true);

      // Account creation
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 0 ? { ...step, status: "processing" } : step,
        ),
      );

      // Wait for account setup and verify status
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await getVerified();
      if (!accountId) {
        throw new Error("Account not properly set up. Please try again.");
      }

      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 0 ? { ...step, status: "success" } : step,
        ),
      );

      // Organization creation
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 1 ? { ...step, status: "processing" } : step,
        ),
      );
      console.log("Updating organization name:", formData.businessName);
      await addAccountName(formData.businessName);
      await updateProfile(formData.logo, formData.firstName);
      await getVerified();

      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 1 ? { ...step, status: "success" } : step,
        ),
      );

      // Program creation
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 2 ? { ...step, status: "processing" } : step,
        ),
      );
      const programPayload = {
        name: formData.businessName,
        description: formData.description,
        imageURL: formData.logo,
        heroImageId: formData.heroImage,
        posterImageId: formData.logo,
        productTypeId: 2,
        QRCode: JSON.stringify(formData.address),
        physicalAddressDetail: formData.address,
        brandColor: formData.brandColor,
        brandPattern: "default",
        cityId: 1,
        stateId: 1,
        countryId: 1,
        discoverable: false,
        joiningAllowed: true,
        emailOptIn: true,
        termsNConditionsEmailOptIn: "",
        accountId: accountId,
      };

      console.log("Program Creation Payload:", programPayload);
      const programResponse = await createUpdateProgram(programPayload);
      console.log("Program Creation Response:", programResponse);
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 2 ? { ...step, status: "success" } : step,
        ),
      );

      // Location setup
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 3 ? { ...step, status: "processing" } : step,
        ),
      );
      const locationPayload = {
        businessName: formData.businessName,
        locationName: formData.businessName,
        address: formData.address,
        country: "Hong Kong S.A.R.",
        logoImageId: formData.logo,
      };
      console.log("Location Creation Payload:", locationPayload);
      await addLocation(programResponse.uuid, locationPayload);
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 3 ? { ...step, status: "success" } : step,
        ),
      );

      // Voucher creation
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 4 ? { ...step, status: "processing" } : step,
        ),
      );
      const voucherPayload = {
        campaignName: `${formData.offerDescription} Discount`,
        imageId: formData.voucherImage,
        startDate: formData.startDate?.toISOString(),
        expiryDate: formData.endDate?.toISOString(),
        location: "",
        requirements: {
          requirements: [
            "Not to be used in conjunction with any other offer",
            null,
          ],
        },
        backgroundColor: "",
        description: formData.offerDescription,
        isValid: true,
        programId: programResponse.id,
        backgroundImageId: formData.logo,
        voucherTypeId: formData.offerType === "percentage" ? 1 : 2,
        valueDescription: `${formData.offerValue}${
          formData.offerType === "percentage" ? "%" : "HKD"
        } OFF`,
        value: Number(formData.offerValue),
        productServiceDescription: formData.offerDescription,
        specialAddress: "",
        eventCode: "",
        eventAddress: "",
        isPartOfVoucherCampaignBank: false,
        autoVoucher: false,
        autoVoucherIsOneTimeUse: false,
        autoVoucherNumberOfVisits: 0,
        referralRewardVoucher: false,
        referralRewardVoucherLevel: 0,
        termsNConditionsURL: null,
      };
      console.log("Voucher Creation Payload:", voucherPayload);
      const voucherResponse = await axiosInstance.post(
        `v2/voucher/create/update`,
        voucherPayload,
        {
          baseURL: process.env.REACT_APP_API_URL,
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "content-type": "application/json",
          },
        },
      );
      console.log("Voucher Creation Response:", voucherResponse);
      setProgressSteps((steps) =>
        steps.map((step, i) =>
          i === 4 ? { ...step, status: "success" } : step,
        ),
      );

      notification.success({
        message: "Success",
        description: "Your loyalty program has been created successfully!",
      });

      // Wait a moment to show all complete
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.location.href = "/dashboard";
    } catch (error: any) {
      console.error("Error in handleSubmit:", error);
      console.error("Error response:", error.response?.data);
      // Mark current step as error
      setProgressSteps((steps) =>
        steps.map((step) =>
          step.status === "processing" ? { ...step, status: "error" } : step,
        ),
      );
      notification.error({
        message: "Error",
        description: `Failed to create loyalty program: ${
          error.response?.data?.message || error.message || "Please try again."
        }`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderEmailStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>First Name</Label>
        <AppInput
          type="text"
          name="firstName"
          placeholder="Enter your first name"
          value={formData.firstName}
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
        />
      </div>

      <div className="onboarding-form__field">
        <Label>Email</Label>
        <AppInput
          type="email"
          name="email"
          placeholder="Enter your business email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </div>

      {isOtpSent && (
        <div className="onboarding-form__field">
          <Label>Verification Code</Label>
          <AppInput
            type="text"
            name="otp"
            placeholder="Enter verification code"
            value={formData.otp}
            onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
          />
        </div>
      )}

      <div className="onboarding-form__turnstile">
        <Turnstile
          siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ""}
          options={{ appearance: "interaction-only" }}
          ref={turnstileRef}
        />
      </div>

      <button
        className="onboarding-form__button"
        onClick={isOtpSent ? handleVerifyOTP : handleSendOTP}
        disabled={
          isSubmitting ||
          !formData.firstName ||
          !formData.email ||
          (isOtpSent && !formData.otp)
        }
      >
        {isSubmitting
          ? "Processing..."
          : isOtpSent
            ? "Verify Code"
            : "Send Verification Code"}
      </button>
    </div>
  );

  const renderBusinessNameStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Business Name</Label>
        <AppInput
          type="text"
          name="businessName"
          placeholder="Enter your business name"
          value={formData.businessName}
          onChange={(e) =>
            setFormData({ ...formData, businessName: e.target.value })
          }
        />
      </div>

      <div className="onboarding-form__field">
        <Label>Description</Label>
        <textarea
          className="onboarding-form__textarea"
          placeholder="Tell us about your business"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button"
          onClick={() => setCurrentStep(2)}
          disabled={
            isSubmitting || !formData.businessName || !formData.description
          }
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderBusinessAddressStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Business Address</Label>
        <AppInput
          type="text"
          name="address"
          placeholder="Enter your business address"
          value={formData.address}
          onChange={(e) =>
            setFormData({ ...formData, address: e.target.value })
          }
        />
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button onboarding-form__button--secondary"
          onClick={() => setCurrentStep(1)}
        >
          Back
        </button>
        <button
          className="onboarding-form__button"
          onClick={() => setCurrentStep(3)}
          disabled={isSubmitting || !formData.address}
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderBrandingStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Brand Color</Label>
        <div className="onboarding-form__color-picker">
          <ColorPicker
            disabledAlpha
            value={formData.brandColor}
            onChange={(color) => {
              setFormData({
                ...formData,
                brandColor: color.toHexString(),
              });
            }}
            presets={[
              {
                label: "",
                colors: [
                  "#e06932", // Orange
                  "#333333", // Charcoal
                  "#ffeed7", // Cream
                  "#04b290", // Teal
                ],
              },
            ]}
          />
          <div className="onboarding-form__color-code">
            {formData.brandColor}
          </div>
        </div>
      </div>

      <div className="onboarding-form__field">
        <Label>Business Logo</Label>
        <div className="onboarding-form__image-upload">
          <Uploader
            type="image"
            width={315}
            height={315}
            message="Upload your business logo"
            onUploaded={(sha) => setFormData({ ...formData, logo: sha })}
            aspectRatio={1}
          />
          <div className="onboarding-form__note">
            Recommended size: 200x200px. Max file size: 5MB
          </div>
        </div>
      </div>

      <div className="onboarding-form__field">
        <Label>Hero Image</Label>
        <div className="onboarding-form__image-upload">
          <Uploader
            type="image"
            width={312}
            height={126}
            message="Upload your hero image for Apple/Google Pass"
            onUploaded={(sha) => setFormData({ ...formData, heroImage: sha })}
            aspectRatio={2.47619048 / 1}
          />
          <div className="onboarding-form__note">
            Recommended size: 312x126px. Max file size: 5MB
          </div>
        </div>
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button onboarding-form__button--secondary"
          onClick={() => setCurrentStep(2)}
        >
          Back
        </button>
        <button
          className="onboarding-form__button"
          onClick={() => setCurrentStep(4)}
          disabled={isSubmitting || !formData.logo || !formData.heroImage}
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderOfferDetailsStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Offer Type</Label>
        <select
          className="onboarding-form__select"
          value={formData.offerType}
          onChange={(e) =>
            setFormData({
              ...formData,
              offerType: e.target.value as "percentage" | "amount",
            })
          }
        >
          <option value="percentage">Percentage Discount</option>
          <option value="amount">Fixed Amount Off</option>
        </select>
      </div>

      <div className="onboarding-form__field">
        <Label>Offer Value</Label>
        <div className="onboarding-form__input-group">
          {formData.offerType === "amount" && (
            <span className="onboarding-form__addon">HKD$</span>
          )}
          <AppInput
            type="number"
            name="offerValue"
            placeholder={`Enter ${
              formData.offerType === "percentage" ? "percentage" : "amount"
            }`}
            value={formData.offerValue}
            onChange={(e) =>
              setFormData({ ...formData, offerValue: e.target.value })
            }
          />
          {formData.offerType === "percentage" && (
            <span className="onboarding-form__addon">%</span>
          )}
        </div>
      </div>

      <div className="onboarding-form__field">
        <Label>Offer Description</Label>
        <textarea
          className="onboarding-form__textarea"
          placeholder="Describe your offer"
          value={formData.offerDescription}
          onChange={(e) =>
            setFormData({ ...formData, offerDescription: e.target.value })
          }
        />
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button onboarding-form__button--secondary"
          onClick={() => setCurrentStep(3)}
        >
          Back
        </button>
        <button
          className="onboarding-form__button"
          onClick={() => setCurrentStep(5)}
          disabled={
            isSubmitting || !formData.offerValue || !formData.offerDescription
          }
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderOfferImageStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Voucher Image</Label>
        <div className="onboarding-form__image-upload">
          <Uploader
            type="image"
            width={315}
            height={315}
            message="Upload your voucher image"
            onUploaded={(sha) => {
              setFormData({ ...formData, voucherImage: sha });
            }}
            aspectRatio={1}
          />
          <div className="onboarding-form__note">
            Recommended size: 315x315px. Max file size: 5MB
          </div>
        </div>
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button onboarding-form__button--secondary"
          onClick={() => setCurrentStep(4)}
        >
          Back
        </button>
        <button
          className="onboarding-form__button"
          onClick={() => setCurrentStep(6)}
          disabled={isSubmitting || !formData.voucherImage}
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderScheduleStep = () => (
    <div className="onboarding-form__fields">
      <div className="onboarding-form__field">
        <Label>Campaign Schedule</Label>
        <div className="onboarding-form__date-picker">
          <DayPicker
            mode="range"
            selected={{
              from: formData.startDate || undefined,
              to: formData.endDate || undefined,
            }}
            footer={
              <div style={{ marginTop: "15px", textAlign: "center" }}>
                {formData.startDate
                  ? `from ${DateTime.fromJSDate(
                      formData.startDate,
                    ).toLocaleString()} `
                  : ""}
                {formData.endDate
                  ? `to ${DateTime.fromJSDate(
                      formData.endDate,
                    ).toLocaleString()}`
                  : ""}
              </div>
            }
            onSelect={(range) => {
              setFormData({
                ...formData,
                startDate: range?.from
                  ? DateTime.fromJSDate(range.from)
                      .set({ hour: 0, minute: 0, second: 0 })
                      .toJSDate()
                  : null,
                endDate: range?.to
                  ? DateTime.fromJSDate(range.to)
                      .set({ hour: 23, minute: 59, second: 59 })
                      .toJSDate()
                  : null,
              });
            }}
            disabled={{ before: new Date() }}
          />
        </div>
      </div>

      <div className="onboarding-form__actions">
        <button
          className="onboarding-form__button onboarding-form__button--secondary"
          onClick={() => setCurrentStep(5)}
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          className="onboarding-form__button"
          onClick={handleSubmit}
          disabled={isSubmitting || !formData.startDate || !formData.endDate}
        >
          {isSubmitting ? "Creating Account..." : "Complete Setup"}
        </button>
      </div>
    </div>
  );

  const renderProgressModal = () => (
    <Modal
      title="Setting up your Loyalty Program"
      open={showProgress}
      footer={null}
      closable={false}
      centered
    >
      <div className="onboarding-form__progress-steps">
        {progressSteps.map((step, index) => (
          <div key={index} className="onboarding-form__progress-step">
            <div className="onboarding-form__progress-step-title">
              {step.title}
            </div>
            <div className="onboarding-form__progress-step-status">
              {step.status === "processing" && (
                <Progress type="circle" percent={100} size={20} />
              )}
              {step.status === "success" && <span className="success">✓</span>}
              {step.status === "error" && <span className="error">×</span>}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );

  return (
    <div className="onboarding-form">
      <div className="onboarding-form__header">
        <h1 className="onboarding-form__brand-title">
          Claim Your Free Voucher on meed
        </h1>
        <img src={meedLogo} alt="meed" className="onboarding-form__logo" />
      </div>

      <div className="onboarding-form__progress">
        <div
          className="onboarding-form__progress-bar"
          style={{ width: `${((currentStep + 1) / 7) * 100}%` }}
        />
      </div>

      <div className="onboarding-form__content">
        <h2 className="onboarding-form__title">{STEP_TITLES[currentStep]}</h2>
        <p className="onboarding-form__description">
          {STEP_DESCRIPTIONS[currentStep as keyof typeof STEP_DESCRIPTIONS]}
        </p>

        {currentStep === 0 && renderEmailStep()}
        {currentStep === 1 && renderBusinessNameStep()}
        {currentStep === 2 && renderBusinessAddressStep()}
        {currentStep === 3 && renderBrandingStep()}
        {currentStep === 4 && renderOfferDetailsStep()}
        {currentStep === 5 && renderOfferImageStep()}
        {currentStep === 6 && renderScheduleStep()}
      </div>
      {renderProgressModal()}
    </div>
  );
}
