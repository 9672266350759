import React, { useState, useMemo } from "react";
import "./cash_voucher.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import Label from "../Label/label";
import Helper from "../Helper/helper";
import Explainer from "../Explainer/explainer";
import AppInput from "../AppInput/app_input";
import useGetCountries from "../../Hooks/getCountries";

function Cash_Voucher() {
  const dispatch = useDispatch();

  const productServiceDescription = useSelector(selectors.getGiveaway);
  const cash_voucher = useSelector(selectors.getCashVoucher);
  const stored_currency = useSelector(selectors.getCurrency);

  const [value, setValue] = useState({
    ref: productServiceDescription ? productServiceDescription : "",
    cash_value: cash_voucher ? cash_voucher : null,
    currency: stored_currency ? stored_currency : null,
  });

  const { countries } = useGetCountries();

  const currencyOptions = useMemo<
    { value: string; description: string }[]
  >(() => {
    if (!countries) {
      return [];
    }

    return Object.values(
      countries.reduce<Record<string, { value: string; description: string }>>(
        (accum, country) => {
          if (!accum[country.currency]) {
            accum[country.currency] = {
              value: country.currency,
              description: `${country.currency} (${country.currency_symbol})`,
            };
          }
          return accum;
        },
        {},
      ),
    );
  }, [countries]);

  return (
    <div className="form">
      <div className="form-content">
        <div className="input__widget">
          <Label>
            Product or service
            <Explainer
              title="
Enter the name of the product or service 
you are applying the voucher to. If everything, type 'everything'!
                "
              size="20"
            />
          </Label>
          <AppInput
            maxLength={20} // Enforces a character limit
            placeholder="Example: Blue cheese or new haircut"
            value={value.ref}
            onChange={(e) => {
              setValue({ ...value, ref: e.target.value });
              dispatch(actions.setGiveaway(e.target.value));
            }}
          />
          <Helper>Maximum 20 characters</Helper>
        </div>
        <div className="voucher__widget">
          <Label>
            Cash voucher
            <Explainer
              title="
Choose the value of your voucher (for cash and percentage discounts)
                "
              size="20"
            />
          </Label>
          <div className="cash-voucher">
            <AppInput
              maxLength={4} // Enforces a character limit
              placeholder="0000"
              value={value.cash_value}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                setValue({ ...value, cash_value: newValue });
                dispatch(actions.setCashVoucher(newValue));
              }}
              pattern="[0-9]*" // Allow only numeric input
            />
            <AppInput
              tag="select"
              value={value.currency}
              onChange={(e) => {
                setValue({ ...value, currency: e.target.value });
                dispatch(actions.setCurrency(e.target.value));
              }}
              selectOptions={currencyOptions}
              selectPlaceholder="Currency"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cash_Voucher;
