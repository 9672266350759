import axiosInstance from "../services/axiosIntercept";
import { useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";

const useBillingStripe = () => {
  const accountUuid = useSelector(selectors.getAccountUuid);

  const getBillingStripe = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/stripe/customer/portal`,
        {
          accountUuid: accountUuid,
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  return getBillingStripe;
};

export default useBillingStripe;
