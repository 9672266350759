import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
  MouseEventHandler,
} from "react";
import styles from "./user.module.css";
import { ReactComponent as ChevronDownMediumIcon } from "./../../assets/symbols/chevron-down-medium.svg";
import { ReactComponent as ChevronRightTiny } from "../../assets/symbols/chevron-right-tiny.svg";
import ImpersonateMerchantModal from "../ImpersonateMerchantModal";
import {
  getAccountName,
  getAccountSubscription,
  getAccountUuid,
  getAccounts,
  getImpersonatingMerchantId,
  getProfileImageId,
} from "../../selectors/selectors";
import { useSelector } from "react-redux";
import {
  setAccount,
  setImpersonatingMerchantId,
  setMerchantUserRole,
  setSelectedLp,
  setVouchers,
} from "../../actions/actions";
import { useDispatch } from "react-redux";
import useGetPrograms from "../../Hooks/getPrograms";
import Profile from "../Profile/profile";
import classNames from "classnames";
import useLimitCheck from "../../Hooks/getLimitCheck";
import { useNavigate } from "react-router-dom";
import useSetAccountChange from "../../Hooks/setAccountChange";
import useBillingStripe from "../../Hooks/billingStripe";

type Props = {
  name: string;
  email: string;
  isSuperAdmin: boolean;
  avatarUrl: string;
  onLogout: () => void;
};
const User: React.FC<Props> = ({
  name,
  isSuperAdmin,
  email,
  avatarUrl,
  onLogout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAccountChange = useSetAccountChange();
  const [isExpanded, setIsExpanded] = useState(false);
  const [profile, setProfile] = useState(false);
  const [impersonateMerchantModalOpen, setImpersonateMerchantModalOpen] =
    useState(false);
  const impersonatingMerchantId = useSelector(getImpersonatingMerchantId);
  const uuid = useSelector(getAccountUuid);
  const organizationName = useSelector(getAccountName);
  const profileImageId = useSelector(getProfileImageId);
  const accounts = useSelector(getAccounts);
  const getPrograms = useGetPrograms(uuid);
  const getLimitCheck = useLimitCheck();
  const billingStripe = useBillingStripe();
  const isPremium = useSelector(getAccountSubscription);

  console.log("isPremium", isPremium);

  const userDropdownRef = useRef<HTMLDivElement>(null);

  console.log("IS SUPER ADMIN", isSuperAdmin);

  function toggleDropdown() {
    setIsExpanded(!isExpanded);
  }

  const closeDropdownOnEsc: KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      setIsExpanded(false);
    }
  };

  const closeDropdownOnOutsideClick: MouseEventHandler = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target as any)
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    getPrograms(uuid);
    getLimitCheck();
  }, [uuid]);

  useEffect(() => {
    document.addEventListener("keydown", closeDropdownOnEsc as any);
    document.addEventListener("mousedown", closeDropdownOnOutsideClick as any);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      document.removeEventListener("keydown", closeDropdownOnEsc as any);
      document.removeEventListener(
        "mousedown",
        closeDropdownOnOutsideClick as any,
      );
    };
  }, []);

  const roleMapping = {
    OWNER: 1,
    ADMIN: 2,
    USER: 3,
  };

  const handleOrgChange = async (account: { userRole: any; account: any }) => {
    dispatch(setAccount(account));
    dispatch(setMerchantUserRole(account.userRole.name));
    await getAccountChange(account.account.uuid);
    setIsExpanded(false);
  };

  return (
    <div
      ref={userDropdownRef}
      className={classNames(
        styles.user,
        isExpanded && styles["user--expanded"],
      )}
    >
      <button
        className={styles["user__handler"]}
        type="button"
        onClick={toggleDropdown}
      >
        <img
          className={styles["user__avatar"]}
          src={
            `${process.env.REACT_APP_CDN_URL}${profileImageId}.jpg` || avatarUrl
          }
          width="64"
          height="64"
          alt=""
        />
        <ChevronDownMediumIcon className={styles["user__chevron"]} />
      </button>
      <div className={styles["user__dropdown"]}>
        <div className={styles["user__name"]}>{name}</div>
        {/* email has to be an <a> tag, otherwise browser will create its own */}
        <div className={styles["user__email"]}>{email}</div>
        <div className={styles["user__organization"]}>{organizationName}</div>
        <div className={styles["user__separator"]}></div>
        <div className={styles["organization-list"]}>
          <a
            className={styles["user__link"]}
            href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span>Organizations</span>
            <ChevronRightTiny className="place__chevron" />
          </a>
          <div className={styles["organization-list__dropdown"]}>
            {accounts?.map((row) => (
              <a
                className={classNames(
                  styles["organization-list__dropdown__link"],
                  row.account.uuid === uuid &&
                    styles["organization-list__dropdown__link--active"],
                )}
                href="javascript:void(0)"
                onClick={async () => {
                  await handleOrgChange(row);
                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 500);
                }}
              >
                {row.account.name || "N/A"}
              </a>
            ))}
          </div>
        </div>
        <div className={styles["user__separator"]}></div>
        <a
          className={styles["user__link"]}
          href="#"
          onClick={() => {
            setProfile(true);
          }}
        >
          Profile
        </a>
        {/* <a className={styles["user__link"]} href="#">
          Settings
        </a>
        <a className={styles["user__link"]} href="#">
          Notifications
        </a> */}
        {/* <a className={styles['user__link']} href="#">
          Your Plan
        </a> */}
        {isSuperAdmin &&
          (impersonatingMerchantId ? (
            <a
              className={styles["user__link"]}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setImpersonatingMerchantId());
                dispatch(setSelectedLp());
                getPrograms(uuid);
                dispatch(setVouchers(null));
              }}
              title={`Stop impersonating ${impersonatingMerchantId}`}
            >
              Stop Impersonating
            </a>
          ) : (
            <a
              className={styles["user__link"]}
              href="#"
              onClick={async (e) => {
                e.preventDefault();
                setImpersonateMerchantModalOpen(true);
              }}
            >
              Impersonate a Merchant
            </a>
          ))}
        <div className={styles["user__separator"]}></div>
        {(isPremium === 1 || isPremium === 2) && (
          <>
            <a
              className={styles["user__link"]}
              href="#"
              onClick={async () => {
                try {
                  const { url } = await billingStripe();
                  if (url) {
                    window.open(url, "_blank");
                  }
                } catch (error) {
                  console.error("Error during billingStripe:", error);
                }
              }}
            >
              Billing
            </a>
            <div className={styles["user__separator"]}></div>
          </>
        )}
        <a
          className={styles["user__link"]}
          href="https://www.meedloyalty.com/software-as-a-service-terms-of-service"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <div className={styles["user__separator"]}></div>
        <a className={styles["user__link"]} href="#" onClick={onLogout}>
          Logout
        </a>
      </div>
      <ImpersonateMerchantModal
        visible={impersonateMerchantModalOpen}
        onClose={() => setImpersonateMerchantModalOpen(false)}
      />
      {profile && (
        <Profile
          data={{
            name,
            email,
            image: `${profileImageId}`,
          }}
          onCancel={() => setProfile(false)}
          onSuccess={() => {
            // refresh();
            setProfile(false);
          }}
        />
      )}
    </div>
  );
};

export default User;
