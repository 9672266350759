import React, { useEffect, useState } from "react";
import useGetLogout from "../../Hooks/getLogout";
import "./approval.css";
import useGetPaymentStatus from "../../Hooks/getPaymentStatus";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";

function Approval() {
  const [countdown, setCountdown] = useState(10);
  const doLogout = useGetLogout();
  const sessionId =
    new URLSearchParams(window.location.search).get("session_id") ?? "";
  const paymentStatus = useGetPaymentStatus(sessionId);
  console.log("sessionId", sessionId);

  const [paymentMessage, setPaymentMessage] = useState(
    "Checking payment status...",
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await paymentStatus;
      console.log("response", response.payment_status);
      if (response.payment_status === "paid") {
        setPaymentMessage("Payment Successful");
      } else if (response.error) {
        setPaymentMessage("Payment Failed");
      } else {
        setPaymentMessage("Checking payment status...");
      }
    };
    fetchData();
  }, [paymentStatus]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          doLogout();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [doLogout]);

  return (
    <div className="approval container">
      <h1>{paymentMessage}</h1>
      {paymentMessage === "Checking payment status..." ? (
        <LoadingSpinner />
      ) : (
        <img
          src={
            paymentMessage === "Payment Successful"
              ? "https://img.icons8.com/?size=100&id=CIdgkSn4RFPP&format=png&color=000000"
              : "https://img.icons8.com/?size=100&id=21067&format=png&color=000000"
          }
          alt={paymentMessage}
          className="approval__image"
        />
      )}
      <p>Redirecting to login page in {countdown} seconds...</p>
      <button className="button" onClick={() => doLogout()}>
        Go to Login
      </button>
    </div>
  );
}

export default Approval;
