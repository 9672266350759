import { isAxiosError } from "axios";
import axiosInstance from "./axiosIntercept";
import { Voucher } from "../types";

const apiUrl = `${process.env.REACT_APP_API_URL}v1/voucher`;

export const fetchProductServiceDescription = async (id: number) => {
  try {
    const productServiceRefId = id ? id : 0;
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/productServiceRef/${productServiceRefId}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data?.description;
  } catch (error) {
    console.error(
      "Error fetching product service reference:",
      isAxiosError(error) ? error.response : error,
    );
    //throw error;
  }
};
export const fetchVouchersByAccount = async (uuid: string) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/account/${uuid}`, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });
    // return response.data;

    const vouchers = response.data;

    for (let voucher of vouchers) {
      const productServiceDescription = await fetchProductServiceDescription(
        voucher.productServiceRefId,
      );
      voucher.productServiceDescription = productServiceDescription;
    }

    return vouchers;
  } catch (error) {
    console.error(
      "Error fetching vouchers:",
      isAxiosError(error) ? error.response : error,
    );
    throw error;
  }
};

export const createUpdateVoucher = async (voucherData: Voucher) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/create`, voucherData, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating /updating voucher:",
      isAxiosError(error) ? error.response : error,
    );
    throw error;
  }
};

/**
 *
 * @param {string} voucherUuid
 * @param {boolean} status
 * @returns {Promise<void>}
 */
export const updateVoucherStatus = async (voucherUuid: string, status: any) => {
  try {
    const response = await axiosInstance.post(
      `${apiUrl}/deactivation`,
      {
        voucherUuid,
        action: !status,
      },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating voucher status:",
      isAxiosError(error) ? error.response : error,
    );
    throw error;
  }
};

type CheckVoucherRestartResponse = {
  canRestart: boolean;
  voucher: string[];
  message: string;
};
export const checkVoucherRestart = async (
  programId: number,
): Promise<CheckVoucherRestartResponse> => {
  try {
    const { data } = await axiosInstance.post<CheckVoucherRestartResponse>(
      "/v1/voucher/checkRestart",
      {
        programId,
      },
      {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );

    return data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};

export const deleteReward = async (rewardId: number): Promise<void> => {
  await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}v1/voucher/delete/reward`,
    { rewardId },
    {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "content-type": "application/json",
      },
    },
  );
};

export const updatePass = async (programId: number): Promise<void> => {
  try {
    await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v1/pass/update`,
      { programId },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );
  } catch (error) {
    console.error("Error updating pass:", error);
  }
};
